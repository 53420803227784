var presentClearpay = presentClearpay || {};

(function ($) {
  $(document).on('click', '.js-product-shade', function (event) {
    event.preventDefault();
    var skuId = $(this).data('sku');

    if (skuId) {
      var sku = prodcat.data.getSku(skuId);
      var skuBaseId;

      if (sku) {
        skuBaseId = sku.SKU_BASE_ID;
        var $product = $(this).closest('.js-product');

        $product.trigger('product.skuSelect', [skuBaseId]);

        // Toggle the active state of the selected shades
        $product.find('.js-product-shade').removeClass('active');
        $(this).addClass('active');
      }
    }
  });

  $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
    $('.js-product-shade', this).removeClass('active');
    $('.js-product-shade[data-sku="SKU' + skuBaseId + '"]', this).addClass('active');
    $('.js-sku-menu-item.active', this).html(skuBaseId);
    if (Drupal.settings.common.clearpay_sku_price && $('.js-sku-price', this).length > 0) {
      var amountClearpay = $('.js-sku-price', this).html().replace(/[^\d]/g, '');

      if (amountClearpay !== null && typeof presentClearpay.clearpayConfig === 'function') {
        presentClearpay.clearpayConfig(amountClearpay);
      }
    }
  });

  $(document).on('mouseenter', '.js-product-shade', function () {
    var $product = $(this).closest('.js-product');
    var skuId = $(this).data('sku');
  });

  $(document).on('mouseleave', '.js-product-shade', function () {
    var $product = $(this).closest('.js-product');
    var skuId = $product.data('sku-base-id');
  });
})(jQuery);
